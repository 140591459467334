import {
  Box,
  Fade,
  Link as ChakraLink,
  Text,
  useToast,
  Button,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useSetAtom, useAtomValue } from 'jotai';
import { useSession } from '@/hooks/useSession';
import { userAtom, clearUserAtom } from '@/atoms/user';

const MobileMenu = ({ isOpen, links }) => {
  const router = useRouter();
  const session = useSession();
  const user = useAtomValue(userAtom);
  const clearUser = useSetAtom(clearUserAtom);
  const isSignedIn = session.accessToken && Boolean(user?.id);
  const toast = useToast();

  return (
    <Fade in={isOpen}>
      <Box
        px={10}
        h={isOpen ? 'auto' : '0'}
        overflow='hidden'
        boxShadow='md'
        display={{ lg: 'none' }}
        bg='blackAlpha.50'>
        {links.map(({ text, href }) => (
          <ChakraLink
            key={text}
            as={NextLink}
            href={href}
            py={4}
            display='block'>
            <Text as='b'>{text}</Text>
          </ChakraLink>
        ))}

        {isSignedIn ? (
          <>
            <ChakraLink
              as={NextLink}
              href='/account/settings'
              py={4}
              display='block'>
              <Text as='b'>設定</Text>
            </ChakraLink>
            <ChakraLink
              as={NextLink}
              href='/account/orders'
              py={4}
              display='block'>
              <Text as='b'>我的訂單</Text>
            </ChakraLink>
          </>
        ) : (
          <ChakraLink
            as={NextLink}
            href={`/login?callbackUrl=${router.pathname}`}
            bg='gray.200'
            display='flex'
            justifyContent='center'
            w='100%'
            px={6}
            py={4}
            my={4}
            borderRadius={10}
            _hover={{
              bg: 'gray.300',
            }}>
            <Text as='b'>登入</Text>
          </ChakraLink>
        )}
      </Box>
    </Fade>
  );
};

export default MobileMenu;
