import { useCallback, useEffect } from 'react';
import {
  Flex,
  Box,
  Button,
  Text,
  Grid,
  GridItem,
  Checkbox,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Icon,
} from '@chakra-ui/react';
import update from 'immutability-helper';
import { MinusIcon } from '@chakra-ui/icons';
import { useAtom, useSetAtom } from 'jotai';
import {
  filterPricingRangeAtom,
  filterTypeListAtom,
  filterInoutListAtom,
} from 'atoms/filter';
import { buildingsAtom } from '@/atoms/buildings';
import {
  DEFAULT_PRICING_RANGE_MIN,
  DEFAULT_PRICING_RANGE_MAX,
} from '@/constants';

import api from '@/services/apis';

import PriceInput from './PriceInput';

const FilterPane = ({ isOpen, onClose }) => {
  const [pricingRange, setPricingRange] = useAtom(filterPricingRangeAtom);
  const [typeList, setTypeList] = useAtom(filterTypeListAtom);
  const [inoutList, setInoutList] = useAtom(filterInoutListAtom);
  const setBuildings = useSetAtom(buildingsAtom);

  const makeOnPriceChange = useCallback(
    (key) => (e) => {
      setPricingRange((prev) => ({
        ...prev,
        [key]: e.target.value,
      }));
    },
    [setPricingRange],
  );

  const makeOnTypeChecked = useCallback(
    (index) => (e) => {
      setTypeList((prev) => {
        return update(prev, {
          [index]: { checked: { $set: e.target.checked } },
        });
      });
    },
    [setTypeList],
  );

  const makeOnInoutChecked = useCallback(
    (index) => (e) => {
      setInoutList((prev) => {
        return update(prev, {
          [index]: { checked: { $set: e.target.checked } },
        });
      });
    },
    [setInoutList],
  );

  const handleReset = async () => {
    setPricingRange((prev) => ({
      ...prev,
      min: DEFAULT_PRICING_RANGE_MIN,
      max: DEFAULT_PRICING_RANGE_MAX,
    }));
    setTypeList((prev) => {
      return prev.map((type) => ({ ...type, checked: false }));
    });
    setInoutList((prev) => {
      return prev.map((inout) => ({ ...inout, checked: false }));
    });

    const response = await api.fetchBuildings();
    if (response?.error) {
      console.log('Error', response.error);
      return;
    }
    setBuildings(response.data);
    onClose();
  };

  const handleFilterSubmit = async () => {
    const params = {
      min_price: pricingRange.min,
      max_price: pricingRange.max,
      tags: [
        ...typeList.filter((type) => type.checked).map((type) => type.value),
        ...inoutList
          .filter((inout) => inout.checked)
          .map((inout) => inout.value),
      ],
    };

    const query = Object.entries(params)
      .map(([key, value]) => {
        if (
          (Array.isArray(value) && value.length > 0) ||
          (!Array.isArray(value) && value)
        ) {
          return `${key}=${value}`;
        }
        return null;
      })
      .filter((item) => item !== null)
      .join('&');

    const response = await api.fetchBuildings({
      query,
    });
    if (response?.error) {
      console.log('Error', response.error);
      return;
    }
    setBuildings(response.data);
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={{ base: 4, lg: 10 }}>
          <Flex direction='column'>
            <Box mb={5}>
              <Text as='b'>租金</Text>
            </Box>
            <Flex align='stretch'>
              <Box flex='1'>
                <PriceInput
                  label='最低價格'
                  onChange={makeOnPriceChange('min')}
                  value={pricingRange.min}
                />
              </Box>
              <Flex align='center' px={2}>
                <Icon as={MinusIcon} />
              </Flex>
              <Box flex='1'>
                <PriceInput
                  label='最高價格'
                  onChange={makeOnPriceChange('max')}
                  value={pricingRange.max}
                />
              </Box>
            </Flex>
            <Divider my={10} />
            <Box mb={5}>
              <Text as='b'>車位類型</Text>
            </Box>
            <Grid templateColumns='repeat(3, 1fr)' gap={5}>
              {typeList.map((type, index) => (
                <GridItem key={type.value}>
                  <Checkbox
                    borderColor='black'
                    iconSize='1rem'
                    onChange={makeOnTypeChecked(index)}
                    isChecked={type.checked}>
                    <Text>{type.label}</Text>
                  </Checkbox>
                </GridItem>
              ))}
            </Grid>
            <Divider my={10} />
            <Box mb={5}>
              <Text as='b'>進出方式</Text>
            </Box>
            <Grid templateColumns='repeat(3, 1fr)' gap={5}>
              {inoutList.map((inout, index) => (
                <GridItem key={inout.value}>
                  <Checkbox
                    borderColor='black'
                    iconSize='1rem'
                    onChange={makeOnInoutChecked(index)}
                    isChecked={inout.checked}>
                    <Text>{inout.label}</Text>
                  </Checkbox>
                </GridItem>
              ))}
            </Grid>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop='1px'
          borderColor='gray.200'
          px={10}
          py={8}
          display='flex'
          alignItems='center'
          justifyContent='space-between'>
          <Button variant='link' fontSize='14px' onClick={handleReset}>
            清除所有選項
          </Button>
          <Button
            bg='primary.blue'
            fontSize='14px'
            color='white'
            _hover={{
              bg: 'primary.darkblue',
            }}
            onClick={handleFilterSubmit}>
            顯示篩選結果
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FilterPane;
