import {
  Flex,
  Box,
  IconButton,
  Container,
  Stack,
  Show,
  useDisclosure,
} from '@chakra-ui/react';
import { LocationArrow as LocationArrowIcon } from '@/assets/icons';
import { NAVBAR_LINKS } from '@/constants';

import AnimatedHamburgerIcon from './AnimatedHamburgerIcon';
import Brand from './Brand';
import DesktopRightMenu from './DesktopRightMenu';
import MobileMenu from './MobileMenu';

function Navbar() {
  const { isOpen: isMobileMenuOpen, onToggle: onMobileMenuToggle } =
    useDisclosure();

  return (
    <Box shadow='md'>
      <Container
        as='nav'
        maxW='container.xl'
        p={0}
        position='sticky'
        top={0}
        zIndex='sticky'
        bg='white'>
        <Flex px={{ base: 4, lg: 10 }} py='3' align='center'>
          {/* 左側區塊 固定出現 */}
          <Flex flex={{ base: 2 }}>
            <Brand />
          </Flex>
          {/* /左側區塊 固定出現 */}

          {/* 右側區塊 桌機版 */}
          <DesktopRightMenu links={NAVBAR_LINKS} />
          {/* /右側區塊 桌機版 */}

          {/* 右側區塊 行動版 無法抽因為每個版本功能不一 */}
          <Show below='lg'>
            <Flex flex={{ base: 1 }}>
              <Stack
                flex={{ base: 1, lg: 0 }}
                justify={'flex-end'}
                direction={'row'}
                spacing={{ base: 2, lg: 6 }}>
                {/* <Button
                as={'a'}
                href={'https://uspace.app.link/download'}
                leftIcon={<LocationArrowIcon color='white' />}
                bg='primary.blue'
                color='white'
                _hover={{
                  bg: 'primary.darkblue',
                }}>
                下載 APP
              </Button> */}
                <IconButton
                  onClick={onMobileMenuToggle}
                  icon={
                    <AnimatedHamburgerIcon
                      isOpen={isMobileMenuOpen}
                      color='black'
                      width={16}
                      height={16}
                    />
                  }
                  bg='white'
                  color='black'
                  _hover={{
                    bg: 'white',
                  }}
                  aria-label={'開關導覽選單'}
                />
              </Stack>
            </Flex>
          </Show>
        </Flex>

        {/* 行動版導覽選單 */}
        <MobileMenu isOpen={isMobileMenuOpen} links={NAVBAR_LINKS} />
        {/* 行動版導覽選單 */}
      </Container>
    </Box>
  );
}

export default Navbar;
