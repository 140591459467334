import { Flex, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';

const PriceInput = ({ onChange, label, value }) => {
  return (
    <FormControl
      isInvalid={false}
      display='flex'
      flexDirection='column'
      border='1px'
      borderColor='gray.300'
      w='100%'
      bg='white'
      px={4}
      py={2}
      borderRadius={8}
      lineHeight='1'>
      {label && (
        <FormLabel fontSize='10px' mb='5px'>
          {label}
        </FormLabel>
      )}
      <Flex align='center'>
        <Text fontSize='16px' mr={1} fontWeight='500'>
          $
        </Text>
        <Input
          type='text'
          variant='unstyled'
          fontSize='16px'
          fontWeight='500'
          p={0}
          value={value}
          onChange={onChange}
        />
      </Flex>
    </FormControl>
  );
};

export default PriceInput;
