import { Flex, Text, Link as ChakraLink, Show } from '@chakra-ui/react';
import Image from 'next/image';
import { logo, logo2x, logo3x } from '@/assets/images';

const Brand = () => {
  return (
    <ChakraLink
      href='/'
      display='flex'
      alignItems='center'
      _hover={{ color: '#dc1428' }}>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}>
        <Image
          src={logo3x}
          srcSet={`${logo} 1x ${logo2x} 2x ${logo3x} 3x`}
          priority={true}
          alt='USPACE 企業識別'
          width={200}
        />
        <Show above='md'>
          <Text as='b' ml={2}>
            月租專區
          </Text>
        </Show>
      </Flex>
    </ChakraLink>
  );
};

export default Brand;
