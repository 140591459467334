import { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  Badge,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SearchIcon,
} from '@chakra-ui/react';
import { v4 as uid } from 'uuid';
import { useAtom, useSetAtom } from 'jotai';
import {
  filterQueryInputAtom,
  filterLocationListAtom,
  filterSpaceListAtom,
  filterGeoLocationAtom,
  filterMapZoomAtom,
  isFilterLocationListOpenAtom,
} from '@/atoms/filter';
import { isBuildingListDrawerOpenAtom } from '@/atoms/buildings';
import api from '@/services/apis';
import { DEFAULT_MAP_ZOOM } from '@/constants';

const FilterLocationInput = () => {
  const setIsBuildingListDrawerOpen = useSetAtom(isBuildingListDrawerOpenAtom);

  // Input 輸入的地址, Atom 全域共用
  // 輸入簡易地址查詢地址列表
  const [queryInput, setQueryInput] = useAtom(filterQueryInputAtom);
  const [locations, setLocations] = useAtom(filterLocationListAtom);
  const [spaces, setSpaces] = useAtom(filterSpaceListAtom);
  const [isLocationListOpen, setIsLocationListOpen] = useAtom(
    isFilterLocationListOpenAtom,
  );
  const setGeoLocation = useSetAtom(filterGeoLocationAtom);
  const setMapZoom = useSetAtom(filterMapZoomAtom);

  const [locationTimer, setLocationTimer] = useState(null);
  const [spaceTimer, setSpaceTimer] = useState(null);

  const searchList = useMemo(() => {
    const newLocations = locations.map((location) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = location;
      return {
        id: uid(),
        name: main_text,
        address: secondary_text,
        placeId: place_id,
        point: null,
        type: 'location',
      };
    });

    const newSpaces = spaces.map((space) => {
      const { building_name, building_address, coordinate } = space;
      return {
        id: uid(),
        name: building_name,
        address: building_address,
        placeId: null,
        point: {
          lat: coordinate?.latitude,
          lng: coordinate?.longitude,
        },
        type: 'space',
      };
    });
    return [...newSpaces, ...newLocations];
  }, [locations, spaces]);

  // <搜尋地址 + Space>
  useEffect(() => {
    if (locationTimer) {
      clearTimeout(locationTimer);
    }

    if (queryInput === '') {
      return;
    }

    const newTimer = setTimeout(async () => {
      const query = queryInput ? `input=${queryInput}` : '';
      const response = await api.fetchFilterLocations({ query });
      if (response?.error) {
        setLocations([]);
        console.log('Error', response.error);
        return;
      }
      setLocations(response.predictions);
    }, 300);
    setLocationTimer(newTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInput, setLocations]);

  useEffect(() => {
    if (spaceTimer) {
      clearTimeout(spaceTimer);
    }

    if (queryInput === '') {
      return;
    }

    const newTimer = setTimeout(async () => {
      const query = queryInput ? `q=${queryInput}&type=subscription` : '';
      if (!query) {
        setSpaces([]);
        return;
      }
      const response = await api.fetchSpaceList({ query });
      if (response?.error) {
        setSpaces([]);
        console.log('Error', response.error);
        return;
      }
      const collapsedSpaces = Array.isArray(response.content)
        ? response.content?.reduce((spaces, current) => {
            const { building_name } = current;
            const isExist = spaces.some(
              (item) => item.building_name === building_name,
            );
            if (!isExist) {
              return [...spaces, current];
            }
            return spaces;
          }, [])
        : [];
      setSpaces(collapsedSpaces);
    }, 300);
    setSpaceTimer(newTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInput, setSpaces]);
  // </搜尋地址 + Space>

  const handleLocationChange = (e) => {
    setQueryInput(e.target.value);
  };

  const handleSelectLocation = async (placeId) => {
    const query = placeId ? `placeid=${placeId}` : '';
    const response = await api.fetchGeoLocation({ query });

    if (response.status === 'OK') {
      const { location } = response.result?.geometry || {};
      if (location) {
        setGeoLocation(location);
      }
    }
  };

  return (
    <Box pos='relative'>
      <Input
        placeholder='🔍 輸入您想承租的地址或地標'
        width={{ base: '100%', md: '300px' }}
        size='sm'
        bg='white'
        value={queryInput}
        onClick={(e) => {
          e.stopPropagation();
          setIsLocationListOpen(true);
        }}
        onFocus={() => {
          setIsLocationListOpen(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setIsLocationListOpen(false);
          }, 300);
        }}
        onChange={handleLocationChange}
      />

      {isLocationListOpen && searchList.length > 0 && (
        <>
          <Box
            py={2}
            mt={2}
            borderRadius='8px'
            borderWidth='1px'
            pos='absolute'
            top='100%'
            left={0}
            bg='white'
            zIndex={1100}
            minW='240px'
            boxShadow='md'
            borderColor='gray.300'>
            {searchList.map(({ id, name, address, placeId, point, type }) => {
              return (
                <Button
                  key={id}
                  variant='ghost'
                  w='100%'
                  fontWeight='normal'
                  borderRadius='0px'
                  display='flex'
                  flexDirection='column'
                  justifyContent='start'
                  alignItems='start'
                  maxW={340}
                  mb={2}
                  onClick={() => {
                    setQueryInput(name);
                    setIsBuildingListDrawerOpen(true);

                    if (type === 'location') {
                      handleSelectLocation(placeId);
                    } else {
                      setGeoLocation(point);
                    }
                    setMapZoom(18);
                  }}>
                  <Flex noOfLines={1} alignItems='center'>
                    {type === 'space' && (
                      <Badge colorScheme='blue' mb={1} mr={1}>
                        停車場
                      </Badge>
                    )}
                    {name}
                  </Flex>
                  <Text fontSize='xs' color='gray.500'>
                    {address}
                  </Text>
                </Button>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default FilterLocationInput;
