import { Icon } from '@chakra-ui/react';

const Location = ({ color = 'black', width = '16px', height = '16px' }) => {
  return (
    <Icon viewBox='0 0 16 20' w={width} h={height} fill='none'>
      <path
        d='M8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10ZM14 8.2C14 4.57 11.35 2 8 2C4.65 2 2 4.57 2 8.2C2 10.54 3.95 13.64 8 17.34C12.05 13.64 14 10.54 14 8.2ZM8 0C12.2 0 16 3.22 16 8.2C16 11.52 13.33 15.45 8 20C2.67 15.45 0 11.52 0 8.2C0 3.22 3.8 0 8 0Z'
        fill={color}
      />
    </Icon>
  );
};

export default Location;
