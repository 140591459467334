import { Icon } from '@chakra-ui/react';

const Period24H = ({ color = 'black', width = '16px', height = '16px' }) => {
  return (
    <Icon viewBox='0 0 24 24' w={width} h={height} fill='none'>
      <path
        d='M13 2.05V4.07C16.95 4.56 20 7.92 20 12C20 15.21 18.08 18 15.28 19.28L13 17V22H18L16.78 20.78C19.91 19.07 22 15.76 22 12C22 6.82 18.05 2.55 13 2.05ZM11 2C9.05 2.2 7.2 2.96 5.68 4.21L7.1 5.63C8.2351 4.75498 9.57983 4.19295 11 4V2ZM4.2 5.68C2.96 7.2 2.2 9.05 2 11H4C4.19 9.58 4.75 8.23 5.63 7.1L4.2 5.68ZM6 8V10H9V11H8C6.9 11 6 11.9 6 13V16H11V14H8V13H9C10.11 13 11 12.11 11 11V10C11 9.46957 10.7893 8.96086 10.4142 8.58579C10.0391 8.21071 9.53043 8 9 8H6ZM12 8V13H15V16H17V13H18V11H17V8H15V11H14V8H12ZM2 13C2.2 14.95 2.97 16.8 4.22 18.32L5.64 16.9C4.76201 15.7656 4.19657 14.4209 4 13H2ZM7.11 18.37L5.68 19.79C7.19938 21.0305 9.04886 21.7988 11 22V20C9.58168 19.8114 8.23907 19.2489 7.11 18.37Z'
        fill={color}
      />
    </Icon>
  );
};

export default Period24H;
