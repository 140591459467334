import { atom } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';

export const accessTokenAtom = atomWithStorage('uspaceAccessToken', '');

export const isLoadingAtom = atom(true);

export const initializeAtom = atom(null, async (get, set) => {
  get(accessTokenAtom);
  set(isLoadingAtom, false);
});

export const signInAtom = atom(null, async (_, set, payload = {}) => {
  const { accessToken } = payload;
  set(accessTokenAtom, accessToken);
  set(isLoadingAtom, false);
});

export const signOutAtom = atom(null, (_, set) => {
  set(accessTokenAtom, RESET);
});
