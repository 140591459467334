import { Icon } from '@chakra-ui/react';

const Play = ({ color = 'black', width = '16px', height = '16px' }) => {
  return (
    <Icon viewBox='0 0 10 12' w={width} h={height} fill='none'>
      <path
        d='M1.93749 11.0208C1.65971 11.2014 1.37832 11.2117 1.09332 11.0517C0.808879 10.8922 0.666656 10.6458 0.666656 10.3125V1.68749C0.666656 1.35415 0.808879 1.10749 1.09332 0.947485C1.37832 0.788041 1.65971 0.798597 1.93749 0.979152L8.72916 5.29165C8.97916 5.45832 9.10416 5.69443 9.10416 5.99999C9.10416 6.30554 8.97916 6.54165 8.72916 6.70832L1.93749 11.0208Z'
        fill={color}
      />
    </Icon>
  );
};

export default Play;
