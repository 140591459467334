import { atom } from 'jotai';
import {
  DEFAULT_PRICING_RANGE_MIN,
  DEFAULT_PRICING_RANGE_MAX,
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM,
} from 'constants';

export const filterPricingRangeAtom = atom({
  min: DEFAULT_PRICING_RANGE_MIN,
  max: DEFAULT_PRICING_RANGE_MAX,
});
// Navbar 地點 Input
export const filterQueryInputAtom = atom('');
// Navbar 搜尋地址列表
export const filterLocationListAtom = atom([]);
// Navbar 混合 Spaces 地點列表
export const filterSpaceListAtom = atom([]);
// FilterLocationInput 地點列表是否開啟
export const isFilterLocationListOpenAtom = atom(false);

// Google Map 使用 center 座標
export const filterGeoLocationAtom = atom(DEFAULT_MAP_CENTER);
export const filterCacheGeoLocationAtom = atom(DEFAULT_MAP_CENTER);
export const filterMapBoundsAtom = atom(null);
export const filterMapZoomAtom = atom(DEFAULT_MAP_ZOOM);

export const filterTypeListAtom = atom([
  {
    label: '室內',
    value: 'indoor',
    checked: false,
  },
  {
    label: '室外',
    value: 'outdoor',
    checked: false,
  },
  {
    label: '平面',
    value: 'plane',
    checked: false,
  },
  {
    label: '機械',
    value: 'mechanical',
    checked: false,
  },
]);
export const filterInoutListAtom = atom([
  {
    label: '車牌辨識',
    value: 'car_plate_recognition_no_control',
    checked: false,
  },
  {
    label: '車梯',
    value: 'car_elevator',
    checked: false,
  },
  {
    label: '捲門',
    value: 'rolling_door_app',
    checked: false,
  },
  {
    label: '地鎖',
    value: 'locker_app',
    checked: false,
  },
]);
