import {
  Show,
  Flex,
  Stack,
  Link as ChakraLink,
  Text,
  Button,
  Divider,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useSetAtom, useAtomValue } from 'jotai';
import { useSession } from '@/hooks/useSession';
import { userAtom, clearUserAtom } from '@/atoms/user';
import { LocationArrow as LocationArrowIcon } from '@/assets/icons';

const DesktopRightMenu = ({ links }) => {
  const router = useRouter();
  const session = useSession();
  const user = useAtomValue(userAtom);
  const clearUser = useSetAtom(clearUserAtom);
  const isSignedIn = session.accessToken && Boolean(user?.id);
  const toast = useToast();

  return (
    <Show above='lg'>
      <Flex flex={{ base: 1 }} justify='end' overflow='hidden'>
        <Stack
          align='center'
          justify={'flex-end'}
          direction={'row'}
          spacing={4}>
          {links.map(({ text, href }) => (
            <ChakraLink
              key={text}
              as={NextLink}
              href={href}
              color={router.pathname === href ? 'primary.blue' : 'black'}>
              <Text fontSize='14px' color='#666'>
                {text}
              </Text>
            </ChakraLink>
          ))}

          <Divider orientation='vertical' height='20px' />

          {isSignedIn ? (
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  size='sm'
                  bg='white'
                  fontWeight='300'
                  rightIcon={<ChevronDownIcon />}>
                  會員中心
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      router.push('/account/settings');
                    }}>
                    設定
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      router.push('/account/orders');
                    }}>
                    我的訂單
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      session.signOut();
                    }}>
                    登出
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          ) : (
            <ChakraLink
              as={NextLink}
              href={`/login?callbackUrl=${router.asPath}`}
              px={3}
              py={1}
              borderRadius={8}
              _hover={{
                bg: 'gray.300',
              }}>
              <Text fontSize='sm' color='#666'>
                登入
              </Text>
            </ChakraLink>
          )}

          {/* <Button
            as={'a'}
            href={'https://uspace.app.link/download'}
            leftIcon={<LocationArrowIcon color='white' />}
            bg='primary.blue'
            color='white'
            _hover={{
              bg: 'primary.darkblue',
            }}>
            下載 APP
          </Button> */}
        </Stack>
      </Flex>
    </Show>
  );
};

export default DesktopRightMenu;
