import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Container,
  Stack,
  HStack,
  Show,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

import { NAVBAR_LINKS } from '@/constants';

import AnimatedHamburgerIcon from './AnimatedHamburgerIcon';
import Brand from './Brand';
import DesktopRightMenu from './DesktopRightMenu';
import MobileMenu from './MobileMenu';

function NavbarWithSearch() {
  const { isOpen: isMobileMenuOpen, onToggle: onMobileMenuToggle } =
    useDisclosure();

  return (
    <Box borderBottom='1px solid #E2E8F0'>
      <Container
        as='nav'
        maxW='container.xl'
        p={0}
        position='sticky'
        top={0}
        zIndex='sticky'
        bg='white'>
        <Flex px={{ base: 4, lg: 10 }} py='3' align='center'>
          {/* 左側區塊 固定出現 */}
          <Flex flex={{ base: 2 }}>
            <Brand />

            {/* 差異化無法統一區塊 */}
            <Show above='lg'>
              <HStack spacing='4' ml='10'>
                <InputGroup size='md' w='300px'>
                  <Input placeholder='開始搜尋' />
                  <InputRightElement width='4.5rem'>
                    <IconButton icon={<SearchIcon />} variant='unstyle' />
                  </InputRightElement>
                </InputGroup>
              </HStack>
            </Show>
            {/* /差異化無法統一區塊 */}
          </Flex>
          {/* /左側區塊 固定出現 */}
          {/* 右側區塊 桌機版 */}
          <DesktopRightMenu links={NAVBAR_LINKS} />
          {/* /右側區塊 桌機版 */}
          {/* 差異化無法統一區塊 */}
          {/* 右側區塊 行動版 */}
          <Show below='lg'>
            <Flex flex={{ base: 1 }}>
              <Stack
                flex={{ base: 1, lg: 0 }}
                justify={'flex-end'}
                direction={'row'}
                spacing={{ base: 2, base: 6 }}>
                {/* <Button
                as={'a'}
                href={'https://uspace.app.link/download'}
                leftIcon={<LocationArrowIcon color='white' />}
                bg='primary.blue'
                color='white'
                _hover={{
                  bg: 'primary.darkblue',
                }}>
                下載 APP
              </Button> */}
                <IconButton
                  onClick={onMobileMenuToggle}
                  icon={
                    <AnimatedHamburgerIcon
                      isOpen={isMobileMenuOpen}
                      color='black'
                      width={16}
                      height={16}
                    />
                  }
                  bg='white'
                  color='black'
                  _hover={{
                    bg: 'white',
                  }}
                  aria-label={'開關導覽選單'}
                />
              </Stack>
            </Flex>
          </Show>{' '}
          {/* /差異化無法統一區塊 */}
        </Flex>

        {/* 行動版導覽選單 */}
        <MobileMenu isOpen={isMobileMenuOpen} links={NAVBAR_LINKS} />
        {/* 行動版導覽選單 */}
      </Container>
    </Box>
  );
}

export default NavbarWithSearch;
