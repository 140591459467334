import { useAtom, useSetAtom } from 'jotai';
import {
  accessTokenAtom,
  isLoadingAtom,
  signInAtom,
  signOutAtom,
} from 'atoms/session';

export const useSession = () => {
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [accessToken] = useAtom(accessTokenAtom);
  const signIn = useSetAtom(signInAtom);
  const signOut = useSetAtom(signOutAtom);

  return {
    isLoading,
    accessToken,
    signIn: (payload) => {
      setIsLoading(true);
      signIn(payload);
    },
    signOut,
  };
};
