import { useEffect, useState, useMemo } from 'react';
import {
  Text,
  Box,
  Flex,
  Input,
  Button,
  IconButton,
  Container,
  Stack,
  HStack,
  Show,
  Badge,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useAtom, useSetAtom, useAtomValue } from 'jotai';
import { v4 as uid } from 'uuid';
import {
  filterTypeListAtom,
  filterInoutListAtom,
  filterQueryInputAtom,
  filterLocationListAtom,
  filterSpaceListAtom,
  filterGeoLocationAtom,
  filterMapZoomAtom,
} from '@/atoms/filter';
import { isBuildingListDrawerOpenAtom } from '@/atoms/buildings';
import {
  Filter as FilterIcon,
  LocationArrow as LocationArrowIcon,
} from '@/assets/icons';
import { NAVBAR_LINKS, DEFAULT_MAP_ZOOM } from '@/constants';
import api from '@/services/apis';

import AnimatedHamburgerIcon from './AnimatedHamburgerIcon';
import Brand from './Brand';
import DesktopRightMenu from './DesktopRightMenu';
import MobileMenu from './MobileMenu';
import FilterLocationInput from './FilterLocationInput';
import FilterPane from './FilterPane';

function NavbarWithFilter() {
  const { isOpen: isMobileMenuOpen, onToggle: onMobileMenuToggle } =
    useDisclosure();
  const { isOpen: isFilterPaneOpen, onClose: onFilterPaneClose } =
    useDisclosure();

  const typeList = useAtomValue(filterTypeListAtom);
  const inoutList = useAtomValue(filterInoutListAtom);
  const filterCount =
    typeList.filter((t) => t.checked).length +
    inoutList.filter((i) => i.checked).length;

  return (
    <Box shadow='md'>
      <Container
        as='nav'
        maxW='container.xl'
        p={0}
        position='sticky'
        top={0}
        zIndex='sticky'
        bg='white'>
        <Flex px={{ base: 4, lg: 10 }} py='2' align='center'>
          {/* 左側區塊 固定出現 */}
          <Flex flex={{ base: 2 }}>
            <Brand />
            {/* 差異化無法統一區塊 */}
            {/* 搜尋功能行動版隱藏 */}
            <Show above='lg'>
              <HStack spacing='4' ml='10'>
                <FilterLocationInput />
              </HStack>
            </Show>
            {/* /搜尋功能行動版隱藏 */}
            {/* /差異化無法統一區塊 */}
          </Flex>
          {/* /左側區塊 固定出現 */}

          {/* 右側區塊 桌機版 */}
          <DesktopRightMenu links={NAVBAR_LINKS} />
          {/* /右側區塊 桌機版 */}

          {/* 差異化無法統一區塊 */}
          {/* 右側區塊 行動版 */}
          <Show below='lg'>
            <Flex flex={{ base: 0 }}>
              <Stack
                flex={{ base: 1, lg: 0 }}
                justify={'flex-end'}
                direction={'row'}
                spacing={{ base: 2, lg: 6 }}>
                {/* <IconButton
                onClick={onFilterOpen}
                icon={<SearchIcon w={3} h={3} />}
                color='black'
                bg='white'
                _hover={{
                  bg: 'white',
                }}
                aria-label={'篩選條件'}
              /> */}
                {/* <Button
                as={'a'}
                href={'https://uspace.app.link/download'}
                leftIcon={<LocationArrowIcon color='white' />}
                color='black'
                bg='white'
                _hover={{
                  bg: 'white',
                }}>
                下載 APP
              </Button> */}
                <IconButton
                  onClick={onMobileMenuToggle}
                  icon={
                    <AnimatedHamburgerIcon
                      isOpen={isMobileMenuOpen}
                      color='black'
                      width={16}
                      height={16}
                    />
                  }
                  bg='white'
                  color='black'
                  _hover={{
                    bg: 'white',
                  }}
                  aria-label={'開關導覽選單'}
                />
              </Stack>
            </Flex>
          </Show>
          {/* /差異化無法統一區塊 */}
        </Flex>

        {/* 行動版導覽選單 */}
        <MobileMenu isOpen={isMobileMenuOpen} links={NAVBAR_LINKS} />
        {/* 行動版導覽選單 */}

        {/* 差異化無法統一區塊 */}
        {/* 搜尋功能 */}
        <FilterPane isOpen={isFilterPaneOpen} onClose={onFilterPaneClose} />
        {/* /搜尋功能 */}
        {/* /差異化無法統一區塊 */}
      </Container>
    </Box>
  );
}

export default NavbarWithFilter;
